export const WEV_APP_SCREENS = [
  {
    src: '/images/main/renewal/wevApp/wev_app_home.png',
    title: '홈',
  },
  {
    src: '/images/main/renewal/wevApp/wev_app_wallet.png',
    title: '위브이 지갑',
  },
  {
    src: '/images/main/renewal/wevApp/wev_app_station.png',
    title: '실시간 충전소 찾기',
  },
  {
    src: '/images/main/renewal/wevApp/wev_app_charge.png',
    title: '실시간 충전 현황',
  },
  {
    src: '/images/main/renewal/wevApp/wev_app_history.png',
    title: '간편 충전 내역',
  },
]
