import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined'
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined'
import { IconButton, LinearProgress } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'next-i18next'
import * as S from './IntroVideo.styled'

type Props = {
  outVideo: boolean
}
//  todo how to handle slow network
const IntroVideo = ({ outVideo }: Props) => {
  const [videoProgress, setVideoProgress] = useState(0)
  const { t } = useTranslation('main')
  const vidRef = useRef<null | HTMLVideoElement>(null)
  const didUserPauseVideo = useRef(false)
  const isVideoMuted = useRef(true)

  const handleClickControls = () => {
    if (vidRef.current) {
      if (vidRef.current.paused) {
        vidRef.current.play()
        didUserPauseVideo.current = false
        return
      }

      vidRef.current.pause()
      didUserPauseVideo.current = true
    }
  }

  const updateVideoProgress = () => {
    if (vidRef.current) {
      const { currentTime } = vidRef.current
      const { duration } = vidRef.current
      const progress = (currentTime / duration) * 100
      setVideoProgress(progress)
    }
  }

  useEffect(() => {
    if (vidRef.current) {
      if (outVideo) {
        vidRef.current.pause()
      } else {
        vidRef.current.play()
      }
    }
  }, [outVideo])

  // const handleScrollBack = () => {
  //   if (didUserPauseVideo.current === false) vidRef.current?.play()
  // }

  return (
    <div className="section relative">
      <S.Content isTextHide={videoProgress > 92}>
        <p style={{ fontWeight: 300 }}>{t('introVideo.subtitle')}</p>
        <h1>&apos;{t('introVideo.title')}&apos;</h1>

        <S.VideoWrapper isTextHide={videoProgress > 92}>
          <video
            ref={vidRef}
            autoPlay
            loop
            muted={isVideoMuted.current}
            onTimeUpdate={updateVideoProgress}
            playsInline
            preload="auto"
            // onPause={handleScrollBack}
          >
            <source src="https://cdn2.wev-charger.com/file/media/WEV_KOR_Clean.mp4 " type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </S.VideoWrapper>

        <S.VideoControllerBox>
          <IconButton color="inherit" onClick={handleClickControls}>
            {vidRef.current?.paused ? <PlayCircleFilledWhiteOutlinedIcon /> : <PauseCircleOutlineOutlinedIcon />}
          </IconButton>
          <div className="w-full ml-1">
            <LinearProgress variant="determinate" value={videoProgress} color="inherit" />
          </div>
        </S.VideoControllerBox>
      </S.Content>
    </div>
  )
}

export default IntroVideo
