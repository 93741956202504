import styled from 'styled-components'

export const LollingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;

  .slide_container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100vw;
    height: 64px;
    overflow: hidden;

    ul {
      position: absolute;
      display: flex;
      width: max-content;
      animation: aniScroll 15s linear infinite;

      .slide {
        display: flex;
        flex-wrap: nowrap;
      }

      .slide.original {
        animation: 60s linear infinite normal none running infiniteAnimation1;
      }

      .slide.clone {
        animation: 60s linear infinite normal none running infiniteAnimation2;
      }
    }
    @media (min-width: 1024px) {
      height: 73px;
    }
  }
  @keyframes infiniteAnimation1 {
    0% {
      transform: translateX(0%);
    }
    50% {
      transform: translateX(-100%);
    }
    50.1% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes infiniteAnimation2 {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-200%);
    }
  }
`

export const LogoItem = styled.div`
  width: 112px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  img {
    max-width: 100%;
    max-height: 100%;
  }
  @media (min-width: 1024px) {
    width: 155px;
    height: 53px;
  }
`
