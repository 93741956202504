import { SelectedCharger } from './types'

export const WHITE_CHARGER = {
  colorName: 'white',
  hexValue: '#fff',
  nameKr: '화이트',
}
export const GRAY_CHARGER = {
  colorName: 'gray',
  hexValue: '#D0D0D0;',
  nameKr: '그레이',
}
export const BLACK_CHARGER = {
  colorName: 'black',
  hexValue: '#000',
  nameKr: '블랙',
}
export const BLUE_CHARGER = {
  colorName: 'blue',
  hexValue: '#00B2FF',
  nameKr: '블루',
}
export const ORANGE_CHARGER = {
  colorName: 'orange',
  hexValue: '#FF6B17',
  nameKr: '오렌지',
}
export const YELLOW_CHARGER = {
  colorName: 'yellow',
  hexValue: '#FFDD2C',
  nameKr: '옐로우',
}
export const MINT_CHARGER = {
  colorName: 'mint',
  hexValue: '#2BEEDE',
  nameKr: '민트',
}
export const PURPLE_CHARGER = {
  colorName: 'purple',
  hexValue: '#917DFF',
  nameKr: '퍼플',
}

export const CHARGER_MODELS_KR = [
  {
    title: (
      <>
        기본에 충실한 성능,
        <br />
        안정적인 충전과 다양한 라인업
      </>
    ),
    modelName: '7/11kW',
    capacities: [7, 11],
    descriptions: [
      <>
        <em>화재 예방형 충전기</em>(PLC모뎀 장착) - 옵션사항
      </>,
      <>
        <em>5가지 색상 및 디자인 특허를 보유</em>한 컴팩트한 디자인
      </>,
      <>
        <em>7인치 터치형 디스플레이</em>로 최적의 UI 제공
      </>,
      <>
        관제 시스템 및 관리자 페이지에서 <em>충전기 온도 실시간 확인</em> 가능
      </>,
      <>간편한 개폐 및 수리용이성 향상</>,
      <>
        자체 개발 충전커넥터&케이블 내장 <em>자동 소화약제로 화재 대응</em> 가능
      </>,
      <>
        <em>벽부형, 스탠드형</em>으로 <em>다양한 환경</em>에 맞춰 사용 가능
      </>,
    ],
    detailsLink: '/wev/product/public_7kW',
    imageSrc: '/images/main/renewal/chargers/publicSlowSpeedCharger/public_slow_speed_charger',
    colors: [GRAY_CHARGER, BLACK_CHARGER, BLUE_CHARGER, ORANGE_CHARGER, YELLOW_CHARGER, MINT_CHARGER],
  },
  {
    title: (
      <>
        WEV 시그니처 모델
        <br />
        빠른충전, 다양한 타입, 다양한 공간
      </>
    ),
    modelName: '30/40kW',
    capacities: [30, 40],
    descriptions: [
      <>
        PLC 모뎀 장착 및 <em>SOC제어로 전기차 배터리 과충전 방지</em>
      </>,
      <>
        자체 개발 충전커넥터&케이블 내장 <em>자동 소화약제로 화재 대응</em> 가능
      </>,
      <>
        <em>21.5인치 대형 디스플레이</em>로 사용자 시인성 및 편리성 향상
      </>,
      <>고효율 기자재 인증 완료</>,
      <>
        네트워크 원격 제어로 <em>실시간 업데이트 및 오류감지 모니터링</em> 가능
      </>,
      <>
        파워모듈 측면 배치로 간편한 <em>개폐 및 수리용이성 향상</em>
      </>,
    ],
    detailsLink: '/wev/product/30kW',
    imageSrc: '/images/main/renewal/chargers/mediumSpeedCharger/medium_speed_charger',
    colors: [YELLOW_CHARGER, BLACK_CHARGER, BLUE_CHARGER],
  },
  {
    title: (
      <>
        WEV 급속 충전기
        <br />
        신속하게 언제 어디서든 간편 충전
      </>
    ),
    modelName: '50/100kW',
    capacities: [50, 100],
    descriptions: [
      <>
        PLC 모뎀 장착 및 <em>SOC제어로 전기차 배터리 과충전 방지</em>
      </>,
      <>
        파워모듈 측면 배치로 간편한 <em>개폐 및 수리용이성 향상</em>
      </>,
      <>고효율 기자재 인증 완료</>,
      <>
        네트워크 원격 제어로 <em>실시간 업데이트 및 오류감지 모니터링</em> 가능
      </>,
      <>
        자체 개발 충전커넥터&케이블 내장 <em>자동 소화약제로 화재 대응</em> 가능
      </>,
      <>
        <em>21.5인치 대형 디스플레이</em>로 사용자 시인성 및 편리성 향상
      </>,
    ],
    detailsLink: '/wev/product/50kW',
    imageSrc: '/images/main/renewal/chargers/50_speed_charger',
    colors: [WHITE_CHARGER],
  },
  {
    title: (
      <>
        WEV 초급속 충전기
        <br />
        언제나 빠르고 편리한 충전
      </>
    ),
    modelName: '200kW',
    capacities: [200],
    descriptions: [
      <>
        PLC 모뎀 장착 및 <em>SOC제어로 전기차 배터리 과충전 방지</em>
      </>,
      <>
        파워모듈 측면 배치로 간편한 <em>개폐 및 수리용이성 향상</em>
      </>,
      <>
        네트워크 원격 제어로 <em>실시간 업데이트 및 오류감지 모니터링</em> 가능
      </>,
      <>자체 개발 충전커넥터&케이블 내장 자동 소화약제로 화재 대응 가능</>,
      <>
        <em>21.5인치 대형 디스플레이</em>로 사용자 시인성 및 편리성 향상
      </>,
    ],
    detailsLink: '/wev/product/200kW',
    imageSrc: '/images/main/renewal/chargers/200_speed_charger',
    colors: [WHITE_CHARGER],
  },
]

export const CHARGER_MODELS_EN = [
  {
    title: (
      <>
        Reliable performance,
        <br />
        Stable charging with various lineups
      </>
    ),
    modelName: '7/11kW',
    capacities: [7, 11],
    descriptions: [
      <>
        <em>Fire prevention charger</em> (with PLC modem) - optional feature
      </>,
      <>
        Compact design with <em>5 color options and design patent</em>
      </>,
      <>
        Provides an optimized UI with a <em>7-inch touch display</em>
      </>,
      <>
        <em>Real-time temperature monitoring</em> available on the control system and admin page
      </>,
      <>Improved ease of opening and repair</>,
      <>
        Built-in charging connector & cable developed in-house with{' '}
        <em>automatic fire suppression agent for fire response</em>
      </>,
      <>
        Suitable for <em>various environments</em> with <em>wall-mounted or stand-type</em> options
      </>,
    ],
    detailsLink: '/wev/product/public_7kW',
    imageSrc: '/images/main/renewal/chargers/publicSlowSpeedCharger/public_slow_speed_charger',
    colors: [GRAY_CHARGER, BLACK_CHARGER, BLUE_CHARGER, ORANGE_CHARGER, YELLOW_CHARGER, MINT_CHARGER],
  },
  {
    title: (
      <>
        WEV Signature Model
        <br />
        Fast charging, various types, various spaces
      </>
    ),
    modelName: '30/40kW',
    capacities: [30, 40],
    descriptions: [
      <>
        Equipped with PLC modem and <em>prevents overcharging of electric vehicle batteries with SOC control</em>
      </>,
      <>
        Built-in charging connector & cable developed in-house with{' '}
        <em>automatic fire suppression agent for fire response</em>
      </>,
      <>
        Enhanced visibility and convenience for users with a <em>21.5-inch large display</em>
      </>,
      <>High-efficiency equipment certification completed</>,
      <>
        <em>Real-time updates and error detection monitoring</em> via network remote control
      </>,
      <>
        Power module placed on the side for easy <em>opening and repair</em>
      </>,
    ],
    detailsLink: '/wev/product/30kW',
    imageSrc: '/images/main/renewal/chargers/mediumSpeedCharger/medium_speed_charger',
    colors: [YELLOW_CHARGER, BLACK_CHARGER, BLUE_CHARGER],
  },
  {
    title: (
      <>
        WEV Fast Charger
        <br />
        Quick and easy charging anytime, anywhere
      </>
    ),
    modelName: '50/100kW',
    capacities: [50, 100],
    descriptions: [
      <>
        Equipped with PLC modem and <em>prevents overcharging of electric vehicle batteries with SOC control</em>
      </>,
      <>
        Power module placed on the side for easy <em>opening and repair</em>
      </>,
      <>High-efficiency equipment certification completed</>,
      <>
        <em>Real-time updates and error detection monitoring</em> via network remote control
      </>,
      <>
        Built-in charging connector & cable developed in-house with{' '}
        <em>automatic fire suppression agent for fire response</em>
      </>,
      <>
        Enhanced visibility and convenience for users with a <em>21.5-inch large display</em>
      </>,
    ],
    detailsLink: '/wev/product/50kW',
    imageSrc: '/images/main/renewal/chargers/50_speed_charger',
    colors: [WHITE_CHARGER],
  },
  {
    title: (
      <>
        WEV Ultra-fast Charger
        <br />
        Fast and convenient charging anytime
      </>
    ),
    modelName: '200kW',
    capacities: [200],
    descriptions: [
      <>
        Equipped with PLC modem and <em>prevents overcharging of electric vehicle batteries with SOC control</em>
      </>,
      <>
        Power module placed on the side for easy <em>opening and repair</em>
      </>,
      <>
        <em>Real-time updates and error detection monitoring</em> via network remote control
      </>,
      <>
        Built-in charging connector & cable developed in-house with automatic fire suppression agent for fire response
      </>,
      <>
        Enhanced visibility and convenience for users with a <em>21.5-inch large display</em>
      </>,
    ],
    detailsLink: '/wev/product/200kW',
    imageSrc: '/images/main/renewal/chargers/200_speed_charger',
    colors: [WHITE_CHARGER],
  },
]

export const CHARGER_MODELS_JP = [
  {
    title: (
      <>
        基本に忠実な性能、
        <br />
        安定した充電と多様なラインアップ
      </>
    ),
    modelName: '7/11kW',
    capacities: [7, 11],
    descriptions: [
      <>
        <em>火災予防型充電器</em> (PLCモデム搭載) - オプション項目
      </>,
      <>
        <em>5色のカラーオプションとデザイン特許を持つ</em> コンパクトなデザイン
      </>,
      <>
        <em>7インチタッチディスプレイ</em> で最適なUIを提供
      </>,
      <>
        管理システムおよび管理者ページで <em>充電器の温度をリアルタイムで確認</em> 可能
      </>,
      <>簡単な開閉と修理のしやすさ向上</>,
      <>
        自社開発の充電コネクタ＆ケーブル内蔵 <em>自動消火剤で火災対応可能</em>
      </>,
      <>
        <em>壁掛け型、スタンド型</em> で <em>様々な環境</em> に対応可能
      </>,
    ],
    detailsLink: '/wev/product/public_7kW',
    imageSrc: '/images/main/renewal/chargers/publicSlowSpeedCharger/public_slow_speed_charger',
    colors: [GRAY_CHARGER, BLACK_CHARGER, BLUE_CHARGER, ORANGE_CHARGER, YELLOW_CHARGER, MINT_CHARGER],
  },
  {
    title: (
      <>
        WEV シグネチャーモデル
        <br />
        高速充電、多様なタイプ、多様な場所
      </>
    ),
    modelName: '30/40kW',
    capacities: [30, 40],
    descriptions: [
      <>
        PLCモデム搭載および <em>SOC制御で電気自動車のバッテリー過充電を防止</em>
      </>,
      <>
        自社開発の充電コネクタ＆ケーブル内蔵 <em>自動消火剤で火災対応可能</em>
      </>,
      <>
        <em>21.5インチの大画面ディスプレイ</em> でユーザーの視認性と利便性が向上
      </>,
      <>高効率機器認証完了</>,
      <>
        ネットワークリモート制御により、<em>リアルタイムのアップデートおよびエラーモニタリング</em> 可能
      </>,
      <>
        パワーモジュールを側面に配置し、<em>開閉および修理のしやすさを向上</em>
      </>,
    ],
    detailsLink: '/wev/product/30kW',
    imageSrc: '/images/main/renewal/chargers/mediumSpeedCharger/medium_speed_charger',
    colors: [YELLOW_CHARGER, BLACK_CHARGER, BLUE_CHARGER],
  },
  {
    title: (
      <>
        WEV 高速充電器
        <br />
        迅速に、いつでもどこでも簡単に充電
      </>
    ),
    modelName: '50/100kW',
    capacities: [50, 100],
    descriptions: [
      <>
        PLCモデム搭載および <em>SOC制御で電気自動車のバッテリー過充電を防止</em>
      </>,
      <>
        パワーモジュールを側面に配置し、<em>開閉および修理のしやすさを向上</em>
      </>,
      <>高効率機器認証完了</>,
      <>
        ネットワークリモート制御により、<em>リアルタイムのアップデートおよびエラーモニタリング</em> 可能
      </>,
      <>
        自社開発の充電コネクタ＆ケーブル内蔵 <em>自動消火剤で火災対応可能</em>
      </>,
      <>
        <em>21.5インチの大画面ディスプレイ</em> でユーザーの視認性と利便性が向上
      </>,
    ],
    detailsLink: '/wev/product/50kW',
    imageSrc: '/images/main/renewal/chargers/50_speed_charger',
    colors: [WHITE_CHARGER],
  },
  {
    title: (
      <>
        WEV 超高速充電器
        <br />
        いつでも迅速で便利な充電
      </>
    ),
    modelName: '200kW',
    capacities: [200],
    descriptions: [
      <>
        PLCモデム搭載および <em>SOC制御で電気自動車のバッテリー過充電を防止</em>
      </>,
      <>
        パワーモジュールを側面に配置し、<em>開閉および修理のしやすさを向上</em>
      </>,
      <>
        ネットワークリモート制御により、<em>リアルタイムのアップデートおよびエラーモニタリング</em> 可能
      </>,
      <>自社開発の充電コネクタ＆ケーブル内蔵、自動消火剤で火災対応可能</>,
      <>
        <em>21.5インチの大画面ディスプレイ</em> でユーザーの視認性と利便性が向上
      </>,
    ],
    detailsLink: '/wev/product/200kW',
    imageSrc: '/images/main/renewal/chargers/200_speed_charger',
    colors: [WHITE_CHARGER],
  },
]
