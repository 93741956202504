import React from 'react'
import Partners from '@src/components/wev/partners/Partners'
import { useTranslation } from 'next-i18next'
import * as S from './Partner.styled'

const Partner = () => {
  const { t } = useTranslation('main')
  return (
    <S.Content data-aos="fade-up">
      <h2>{t('partner.협력사')}</h2>
      <Partners />
    </S.Content>
  )
}

export default Partner
