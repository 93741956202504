import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > *:not(:last-child) {
    margin-bottom: 50px;
    @media ${({ theme }) => theme.device.mobile} {
      margin-bottom: 30px;
    }
  }

  & > h2 {
    padding: 0px 20px;
    text-align: center;
    background: linear-gradient(150deg, #2beede 10%, #583eee 90%);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    ${({ theme }) => theme.wev_font.Bold_36}
  }
`

export const SelectChargerButton = styled.button<{ isSelected: boolean }>`
  padding: 30px 6rem;
  width: 100%;
  border-radius: 20px;
  border: 1px solid ${({ theme, isSelected }) => (isSelected ? theme.wev_color.primary : theme.wev_color.outline)};
  color: ${({ theme, isSelected }) => (isSelected ? theme.wev_color.primary : theme.wev_color.text)};
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.wev_color.sub_btn_bg : '#fff')};
  ${({ theme, isSelected }) => (isSelected ? theme.wev_font.sBold_20 : theme.wev_font.Regular_20)};
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
  text-align: center;

  @media screen and (max-width: 900px) {
    padding: 1rem 0;
  }
  @media ${({ theme }) => theme.device.mobile} {
    border-radius: 10px;
  }
`

export const ChargerContainer = styled.div`
  display: flex;
  gap: 100px;
  justify-content: space-between;
  width: 100%;
  overflow-x: hidden;
  & > #media {
    width: 100%;
    max-width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > #controller {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-around;
      margin-top: 50px;
    }
  }

  & > #description {
    border: 1px solid ${({ theme }) => theme.wev_color.outline};
    border-radius: 30px;
    width: 100%;
    height: fit-content;
    padding: 50px;
    display: flex;
    flex-direction: column;
    @media ${({ theme }) => theme.device.mobile} {
      border-radius: 20px;
    }

    & > #detailsLink {
      border: 1px solid ${({ theme }) => theme.wev_color.outline};
      padding: 0.5rem 1.5rem;
      display: flex;
      width: fit-content;
      align-items: center;
      border-radius: 2rem;
      margin-top: 2rem;
      align-self: end;
      ${({ theme }) => theme.wev_font.Regular_16};
      @media ${({ theme }) => theme.device.mobile} {
        margin-top: 20px;
      }
    }
    > ul {
      padding-left: 20px;
    }

    & li {
      list-style-position: inside;
      text-indent: -20px;
      ${({ theme }) => theme.wev_font.Regular_20};
      list-style-type: disc;
      list-style-position: inside;
      margin-bottom: 0.5rem;
      & > em {
        ${({ theme }) => theme.wev_font.sBold_20};
        font-style: normal;
      }
    }
  }

  @media ${({ theme }) => theme.device.pc} {
    flex-direction: column;
    padding: 0;
    align-items: center;
    margin-top: 70px;
    & > #description {
      min-width: unset;
      width: 100%;
      padding: 3rem;
      margin-top: 2rem;
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    gap: 20px;
    margin-top: 0;
    & > #media {
      & > #controller {
        margin-top: 30px;
      }
    }
    & > #description {
      padding: 1rem;
    }
  }
`

export const ChargerColorButton = styled.button<{ selectedColor: string; isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    color: ${({ isSelected, theme }) => (isSelected ? theme.wev_color.text : theme.wev_color.hint)};
    ${({ theme }) => theme.wev_font.Regular_16};
  }
  & > .colorSelectorWrapper {
    position: relative;
    & > svg {
      color: ${({ theme }) => theme.wev_color.primary};
      position: absolute;
      top: -0.25rem;
      right: -0.25rem;
    }
    & > .colorSelector {
      width: 2.5rem;
      height: 2.5rem;
      background-color: ${({ selectedColor }) => selectedColor};
      border-radius: 50%;
      outline: 1px solid
        ${({ selectedColor, theme }) => (selectedColor === '#fff' ? ` ${theme.wev_color.outline} ` : 'none')};
      margin-bottom: 10px;
    }
  }
`
