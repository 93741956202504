import styled from 'styled-components'
import { useRouter } from 'next/router'
import Image from 'next/image'

const Content = styled.div`
  width: 100%;
  position: relative;
`
const Background = styled.div`
  background-color: rgba(88, 62, 238, 0.05);
  width: 85%;
  /* max-width: 1400px; */
  display: flex;
  justify-content: center;
  position: absolute;
  border-top-right-radius: 300px;
  border-bottom-right-radius: 300px;
  height: 100%;
  @media ${({ theme }) => theme.device.pc} {
    width: 95%;
  }
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 120px 70px 120px;
  @media ${({ theme }) => theme.device.mobile} {
    padding: 20px 50px 20px 30px;
  }
`

const MembershipProcess = () => {
  const router = useRouter()
  const locale = router.locale || 'ko'
  const renderImage = (language: string) => {
    switch (language) {
      case 'en':
        return '/images/main/renewal/wevApp/wev_app_process_EN.png'
      case 'jp':
        return '/images/main/renewal/wevApp/wev_app_process_JP.png'
      default: // 한국어(kr)를 기본으로 설정
        return '/images/main/renewal/wevApp/wev_app_process_KR.png'
    }
  }
  return (
    <Content data-aos="fade-up">
      <Background />
      <ImageWrapper>
        <Image src={renderImage(locale)} width={942} height={595} alt="멤버십 발급 프로세스" />
      </ImageWrapper>
    </Content>
  )
}

export default MembershipProcess
