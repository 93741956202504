import React, { useEffect, useState } from 'react'
import { Container } from '@mui/material'
import Link from 'next/link'
import { Arrow_Right_Alt } from '@src/assets/icons'
import { supportApis } from '@src/features/support/api'
import { INewsList } from '@src/features/support/types'
import { useRouter } from 'next/router'
import { nanoid } from '@reduxjs/toolkit'
import { useResponsive } from '@src/hooks/useResponsive'
import { useTranslation } from 'next-i18next'
import { convertUtcToZone } from '@src/utils/timeCalculator'
import { useAppSelector } from '@src/hooks/redux'
import * as S from './News.styled'

const News = () => {
  const router = useRouter()
  const { t } = useTranslation('main')
  const { isMobile } = useResponsive(768)
  const [newsList, setNewsList] = useState<INewsList | null>(null)
  const newUserInfo = useAppSelector((state) => state.userInfo.userInfoMasking)

  const fetchData = async () => {
    const newFilter = {
      page: 1,
      query: '',
    }

    try {
      await supportApis.getNewsList(newFilter).then((res) => {
        if (res.data.resultCode === 'OK') {
          const { records } = res.data.resultData
          if (records === null || records.length === 0) {
            setNewsList(null)
            return
          }
          setNewsList(res.data.resultData)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [router])
  if (!newsList) {
    return null
  }
  return (
    <Container maxWidth="lg" sx={{ mb: isMobile ? '70px' : '120px' }} data-aos="fade-up">
      <S.Content>
        <h2>WEV {t('news.title')}</h2>
        <Link id="detailsLink" className="space-x-2" href={'/customer/news'}>
          <span>{t('news.뉴스 더보기')}</span>
          <Arrow_Right_Alt width={isMobile ? 18 : 32} />
        </Link>
        <S.NewsList>
          {newsList.records.slice(0, 5).map((el) => (
            <S.NewsItem key={nanoid()} onClick={() => router.push(`/customer/news/${el.id}`)}>
              <div className="news_list_image">
                {el.representativeImage ? (
                  <img src={el.representativeImage} alt={`기업뉴스 대표 이미지_${el.id}`} />
                ) : (
                  <S.DefaultImage>
                    <img src={'/images/wev/wev_logo.png'} alt="wev 로고 이미지" />{' '}
                  </S.DefaultImage>
                )}
              </div>
              <S.ItemContent>
                <div>
                  <S.NewsTitle>{el.title}</S.NewsTitle>
                  <span> {convertUtcToZone(el.createdAt, newUserInfo.timeZone).split(' ')[0]}</span>
                </div>
                {!isMobile && <p>{el.contentPreview}</p>}
              </S.ItemContent>
            </S.NewsItem>
          ))}
        </S.NewsList>
      </S.Content>
    </Container>
  )
}

export default News
