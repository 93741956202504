import React, { useEffect, useRef, useState } from 'react'
import IntroVideo from './introVideo/IntroVideo'
import Service from './service/Service'
import WevApp from './wevApp/WevApp'
import Membership from './membership/Membership'
import Charger from './charger/Charger'
import News from './news/News'
import { useAppDispatch } from '@src/hooks/redux'
import { setScrollSidebarIconColor } from '@src/store/modules/commons'
import Partner from './partner/Partner'
import * as S from './Main.styled'

const Main = () => {
  const dispatch = useAppDispatch()
  const [outVideo, setOutVideo] = useState(false)

  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleScroll = () => {
      if (!ref.current) return

      const { top } = ref.current.getBoundingClientRect()

      if (top * -1 >= window.innerHeight) {
        setOutVideo(true)
        dispatch(setScrollSidebarIconColor('rgb(0, 0, 0)'))
      } else {
        setOutVideo(false)
        dispatch(setScrollSidebarIconColor('#fff'))
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [dispatch])

  return (
    <S.MainWrapper>
      <S.VideoSection ref={ref}>
        <IntroVideo outVideo={outVideo} />
      </S.VideoSection>
      <S.ContentSection>
        <Service />
        <WevApp />
        <Membership />
        <Charger />
        <News />
        <Partner />
      </S.ContentSection>
    </S.MainWrapper>
  )
}

export default Main
