import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 120px 0px 50px 0px;
  @media ${({ theme }) => theme.device.mobile} {
    margin: 70px 0;
  }
  & .swiper {
    width: 100%;
  }

  & .swiper-slide {
    padding-top: 20px;
    width: fit-content;
    & #swiperSlideBlur {
      width: 100%;
      height: 100%;
      border-radius: 30px;
      background-color: rgba(255, 255, 255, 0.6);
      z-index: 1;
      position: absolute;
    }
    > div {
      border-radius: 30px;
      border: 8px solid #212121;
      box-shadow: 0px 0px 10px 0px #00000040;
      > img {
        border-radius: 20px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  & h2,
  & p {
    text-align: center;
  }

  & > * {
    margin-bottom: 70px;
    @media ${({ theme }) => theme.device.mobile} {
      margin-bottom: 30px;
    }
  }

  & > #intro {
    padding: 0px 20px;
    & > h2 {
      background-image: linear-gradient(107deg, #2beede 23.91%, #583eee 100%);
      background-clip: text;
      color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      ${({ theme }) => theme.wev_font.Bold_36}
    }

    > p {
      margin-top: 20px;
      ${({ theme }) => theme.wev_font.Regular_20};
    }
  }
`

export const Download = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > * {
    margin-bottom: 20px;
    @media ${({ theme }) => theme.device.mobile} {
      margin-bottom: 30px;
    }
  }
  & #wevLogo {
    border-radius: 40px;
    width: 112px;
    height: 112px;
    background: linear-gradient(199deg, #583eee 16.33%, #3ec4ee 96.86%);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & #wevLogo + p {
    ${({ theme }) => theme.wev_font.sBold_20}
  }

  & .downloadButton {
    outline: 1px solid ${({ theme }) => theme.wev_color.outline};
    border-radius: 6rem;
    padding: 1rem 1.5rem;
    display: flex;
  }
  & > #qr {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > p {
      margin-top: 1rem;
      color: ${({ theme }) => theme.wev_color.hint};
      ${({ theme }) => theme.wev_font.Regular_14};
    }
  }
`
export const ImageBg = styled.div`
  width: 500px;
  height: 250px;
  position: absolute;
  bottom: -100px;
  @media ${({ theme }) => theme.device.mobile} {
    bottom: -50px;
  }
`

export const Slider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 660px;
`

export const ImageItem = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 590px 1fr;
  width: 280px;
  > div {
    border-radius: 30px;
    border: 8px solid #212121;
    box-shadow: 0px 0px 10px 0px #00000040;

    > img {
      border-radius: 20px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  > p {
    margin-top: 20px;
    ${({ theme }) => theme.wev_font.sBold_20};
  }
`
