import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  @media (min-width: 1024px) {
    margin-bottom: 100px;
  }
  > a {
    display: flex;
    width: fit-content;
    align-items: center;
    margin-top: 30px;
    align-self: end;
    > span {
      ${({ theme }) => theme.wev_font.Regular_18}
    }
  }

  & > h2 {
    margin-bottom: 30px;
    padding: 0px 20px;
    text-align: center;
    background: linear-gradient(150deg, #2beede 10%, #583eee 90%);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    ${({ theme }) => theme.wev_font.Bold_36}
    @media (min-width: 1024px) {
      margin-bottom: 50px;
    }
  }
`
