export const MEMBERSHIP_ROAMING_DATA = [
  {
    img_url: '/images/company/roaming/me.png',
    text: '환경부',
  },
  {
    img_url: '/images/company/roaming/aea.png',
    text: '한국자동차환경협회',
  },
  {
    img_url: '/images/company/roaming/skrent.png',
    text: 'SK렌터카(EV링크)',
  },
  {
    img_url: '/images/company/roaming/dlive.png',
    text: `딜라이브`,
  },
  {
    img_url: '/images/company/roaming/manageon.png',
    text: '매니지온',
  },
  {
    img_url: '/images/company/roaming/volta.png',
    text: '볼타',
  },
  {
    img_url: '/images/company/roaming/blue.png',
    text: '블루네트웍스',
  },
  {
    img_url: '/images/company/roaming/scng.png',
    text: '서울씨앤지',
  },
  {
    img_url: '/images/company/roaming/smevc.png',
    text: '성민기업',
  },
  {
    img_url: '/images/company/roaming/ion.png',
    text: '아이온커뮤니케이션즈',
  },
  {
    img_url: '/images/company/roaming/enlighten.png',
    text: '엔라이튼',
  },
  {
    img_url: '/images/company/roaming/evsis.png',
    text: '이브이시스',
  },
  {
    img_url: '/images/company/roaming/evp.png',
    text: '이브이파트너스',
  },
  {
    img_url: '/images/company/roaming/enh.png',
    text: '이앤에이치에너지',
  },
  {
    img_url: '/images/company/roaming/ecplug.png',
    text: '이카플러그',
  },
  {
    img_url: '/images/company/roaming/chaji.png',
    text: '차지인',
  },
  {
    img_url: '/images/company/roaming/crocus.png',
    text: `크로커스`,
  },
  {
    img_url: '/images/company/roaming/tardis.png',
    text: '타디스테크놀로지',
  },
  {
    img_url: '/images/company/roaming/tsct.png',
    text: '태성콘텍',
  },
  {
    img_url: '/images/company/roaming/elecvery.png',
    text: '일렉베리',
  },
  {
    img_url: '/images/company/roaming/kevcs.png',
    text: '한국전기차중전서비스',
  },
  {
    img_url: '/images/company/roaming/kef.png',
    text: '한국전자금융',
  },
  {
    img_url: '/images/company/roaming/hs.png',
    text: '한화솔루션',
  },
  {
    img_url: '/images/company/roaming/humax.png',
    text: '휴맥스이브이',
  },

  {
    img_url: '/images/company/roaming/sgsafety.png',
    text: 'SG생활안전',
  },
  {
    img_url: '/images/company/roaming/skellink.png',
    text: 'SK일렉링크',
  },
  {
    img_url: '/images/company/roaming/gridwiz.png',
    text: '그리드위즈',
  },
  {
    img_url: '/images/company/roaming/redeng.png',
    text: '레드이엔지',
  },
  // {
  //   img_url: '/images/company/roaming/',
  //   text: '미래에스디',
  // },
  {
    img_url: '/images/company/roaming/bep.png',
    text: '브라이트에너지파트너스',
  },
  {
    img_url: '/images/company/roaming/sks.png',
    text: '선광시스템',
  },
  {
    img_url: '/images/company/roaming/softberry.png',
    text: '소프트베리',
  },
  {
    img_url: '/images/company/roaming/mc.png',
    text: '모두의충전',
  },
  {
    img_url: '/images/company/roaming/starkoff.png',
    text: '스타코프',
  },
  {
    img_url: '/images/company/roaming/imarket.png',
    text: '아이마켓코리아',
  },

  {
    img_url: '/images/company/roaming/evar.png',
    text: '에바',
  },
  {
    img_url: '/images/company/roaming/lselink.png',
    text: '엘에스이링크',
  },
  {
    img_url: '/images/company/roaming/uniev.png',
    text: '유니이브이',
  },
  {
    img_url: '/images/company/roaming/well.png',
    text: '이브이루씨',
  },
  {
    img_url: '/images/company/roaming/koolsign.png',
    text: '쿨사인',
  },
  {
    img_url: '/images/company/roaming/klinx.png',
    text: '클린일렉스',
  },
  {
    img_url: '/images/company/roaming/twois.png',
    text: '투이스이브이씨',
  },
  {
    img_url: '/images/company/roaming/iparking.png',
    text: '파킹클라우드',
  },
  {
    img_url: '/images/company/roaming/pumpkin.png',
    text: '펌프킨',
  },
  {
    img_url: '/images/company/roaming/pluglink.png',
    text: '플러그링크',
  },
  {
    img_url: '/images/company/roaming/pline.png',
    text: '피라인모터스',
  },
  {
    img_url: '/images/company/roaming/esc.png',
    text: '한국EV충전서비스센터',
  },
  {
    img_url: '/images/company/roaming/hanm.png',
    text: '한마음장애인복지회',
  },
  {
    img_url: '/images/company/roaming/sksignet.png',
    text: 'sk시그넷',
  },
]

export const COMPANY_PARTNER_DATA = [
  {
    img_url: '/images/company/partner/ke.png',
    text: '한국전력공사',
  },
  {
    img_url: '/images/company/partner/chungbuk.png',
    text: '충청도',
  },
  {
    img_url: '/images/company/partner/nyj.png',
    text: '남양주시',
  },
  {
    img_url: '/images/company/partner/lgchemistry.png',
    text: 'LG화학',
  },
  {
    img_url: '/images/company/partner/coex.png',
    text: '코엑스',
  },
  {
    img_url: '/images/company/partner/kec.png',
    text: '한국환경공단',
  },
  {
    img_url: '/images/company/partner/kosme.png',
    text: '중소기업벤처진흥공단',
  },
  {
    img_url: '/images/company/partner/hwell.png',
    text: '건강보험공단원주본부',
  },
  {
    img_url: '/images/company/partner/daekyeung.png',
    text: '대경대학교',
  },
  {
    img_url: '/images/company/partner/bongsun.png',
    text: '봉선사',
  },
  {
    img_url: '/images/company/partner/tecno.png',
    text: '경기대진테크노파크',
  },
  {
    img_url: '/images/company/partner/nyjhos.png',
    text: '남양주한양병원',
  },
]
