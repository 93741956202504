import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > a {
    display: flex;
    width: fit-content;
    align-items: center;
    margin-top: 30px;
    align-self: end;
    > span {
      ${({ theme }) => theme.wev_font.Regular_18}
    }
  }

  & > h2 {
    padding: 0px 20px;
    text-align: center;
    background: linear-gradient(150deg, #2beede 10%, #583eee 90%);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    ${({ theme }) => theme.wev_font.Bold_36}
  }
`

export const ListWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-top: 20px;
  @media ${({ theme }) => theme.device.pc} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 20px;
    display: block;
  }
`

export const NewsList = styled.ul`
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid ${({ theme }) => theme.wev_color.outline};
`

export const NewsItem = styled.li`
  display: flex;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.wev_color.outline};
  padding: 20px;
  width: 100%;
  cursor: pointer;
  .news_list_image {
    width: 100%;
    max-width: 260px;
    min-width: 84px;
    aspect-ratio: 5/3;
    overflow: hidden;
    > img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      aspect-ratio: 5/3;
      &:hover {
        transform: scale(1.2);
        transition-duration: 0.5s;
      }
    }
  }
  &:hover {
    background-color: #f9f9f9;
  }
  @media (min-width: 1024px) {
    gap: 30px;
    padding: 20px;
    .news_list_image {
      max-width: 300px;
    }
  }
`

export const ItemContent = styled.div`
  width: 100%;
  > div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 180px;
    ${({ theme }) => theme.wev_font.Regular_16};
    > span {
      width: 100%;
      text-align: right;
      color: ${({ theme }) => theme.wev_color.hint};

      white-space: nowrap;
    }
  }
  @media all and (min-width: 768px) and (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      min-width: 230px;

      > span {
        width: fit-content;
        color: ${({ theme }) => theme.wev_color.hint};
      }
    }
    > p {
      max-height: 48px;
      max-width: 740px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      white-space: normal;
    }
  }
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      min-width: unset;
      > span {
        width: fit-content;
        color: ${({ theme }) => theme.wev_color.hint};
      }
    }
    > p {
      max-height: 57px;
      max-width: 740px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      white-space: normal;
    }
  }
`
export const NewsTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  ${({ theme }) => theme.clamp.line2};

  ${({ theme }) => theme.wev_font.sBold_20}
`

export const DefaultImage = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.wev_color.outline};
`
